import React, { ChangeEventHandler, FormEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { User } from '../../types/User';
import { api } from '../../utils/fetch';
import { Loader } from '../Loader';
import { TextInputField } from '../TextInputField';
import { ChargeUserModal } from './ChargeUserModal';
import { EditUserBalanceModal } from './EditUserBalanceModal';
import { UserInfoTab } from './Tabs/UserInfoTab';
import { CompanyInfoTab } from './Tabs/CompanyInfoTab';
import { TransactionsTab } from './Tabs/TransactionsTab';
import { PaymentsTab } from './Tabs/PaymentsTab';
import { CardsTab } from './Tabs/CardsTab';
import { NavTabs } from './Tabs/NavTabs';
import { Team } from '../../types/Team';
import { TeamTab } from './Tabs/TeamTab';
import { enqueueSnackbar } from 'notistack';
import { DeletePrompt } from '../DeletePrompt';

type Card = {
  id: string;
  brand: string;
  number: string;
  bank: string;
  cardHolder: string;
};

export enum Tabs {
  UserInfoTab = 'UserInfoTab',
  CompanyInfoTab = 'CompanyInfoTab',
  PaymentsTab = 'PaymentsTab',
  TransactionsTab = 'TransactionsTab',
  CardsTab = 'CardsTab',
  TeamTab = 'TeamTab',
}

export const UserForm: React.FC = () => {
  const { userId } = useParams();
  const navigate = useNavigate();

  const [tab, setTab] = useState<Tabs>(Tabs.UserInfoTab);
  const [isPaymentModal, setIsPaymentModal] = useState(false);
  const [isBalanceModal, setIsBalanceModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeacivate, setIsDeactivate] = useState(false);
  const [currentUser, setCurrentUser] = useState<Omit<User, 'balance'> & { password: string }>({
    id: 0,
    firstName: '',
    lastName: '',
    picture: '',
    email: '',
    phoneNumber: '',
    domains: [],
    domain: null,
    region: '',
    employees: '',
    role: '',
    paymentType: '',
    password: '',
    isVatPayer: false,
    vatNumber: '',
    reseller: 'reseller-estonia',
    companyName: '',
    companyNumber: '',
    address: '',
    city: '',
    postalCode: '',
    currency: 'EUR',
    fullAddress: '',
    activeCard: '',
    teamId: '',
    adminRole: 'user',
  });
  const [balances, setBalances] = useState([
    {
      amount: 0,
      currency: 'EUR',
    },
  ]);
  const [cards, setCards] = useState<Card[] | undefined>([
    {
      id: '',
      brand: '',
      number: '',
      bank: '',
      cardHolder: '',
    },
  ]);
  const [teams, setTeams] = useState<Team[] | null>(null);

  const closePaymentModal = () => {
    setIsPaymentModal(false);
  };
  const closeBalanceModal = () => {
    setIsBalanceModal(false);
  };
  const setUserDomains = (domains: string[]) =>
    setCurrentUser((prevState) => ({
      ...prevState,
      domains,
    }));
  const setUserActiveCard = (cardId: string) =>
    setCurrentUser((prevState) => ({
      ...prevState,
      activeCard: cardId,
    }));

  const getUserFromAPI = async (id: string) => {
    setIsLoading(true);

    try {
      const teamsFromApi = await api.get<Team[]>('/teams');
      const res = await api.get<User>('/users/' + id);

      setTeams(teamsFromApi);
      setCurrentUser({ ...res, password: '' });
    } catch (e) {
      console.error(e);
      const err = e as Error;
      enqueueSnackbar(`Failed to get team or user: ${err.message}`, { variant: 'error' });
    }

    setIsLoading(false);
  };

  const formSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSaving(true);
    try {
      if (userId === 'new') {
        if (!currentUser.email || !currentUser.password) {
          enqueueSnackbar('User email and password should not be empty', { variant: 'warning' });
          setIsSaving(false);
          return;
        }
        await api.post('/users', currentUser);
        enqueueSnackbar('Created!', { variant: 'success' });
      } else {
        await api.patch('/users/' + userId, currentUser);
        enqueueSnackbar('Saved!', { variant: 'success' });
        getUserFromAPI(userId || '');
      }
    } catch (e) {
      console.error(e);
      const err = e as Error;
      enqueueSnackbar(`Failed to save user: ${err.message}`, { variant: 'error' });
    }
    setIsSaving(false);
  };

  useEffect(() => {
    if (userId && userId !== 'new') {
      getUserFromAPI(userId);
    }
  }, []);

  const { email, password, activeCard, currency } = currentUser;

  const handleChange: ChangeEventHandler<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> = (e) => {
    const { name, value } = e.target;

    if (name === 'isVatPayer') {
      setCurrentUser((prevState) => ({ ...prevState, isVatPayer: value === '+' }));
      return;
    }

    setCurrentUser((prevState) => ({ ...prevState, [name]: value ? value : undefined }));
  };
  const handleDeactivateUser = async () => {
    try {
      await api.delete('/users/' + userId);
      navigate('/users');
    } catch (e) {
      const err = e as Error;
      enqueueSnackbar(`Failed to delete user: ${err.message}`, { variant: 'error' });
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <>
      {isPaymentModal && <ChargeUserModal closeModal={closePaymentModal} />}

      {isBalanceModal && (
        <EditUserBalanceModal closeModal={closeBalanceModal} balances={balances} currency={currency} />
      )}

      {userId !== 'new' && <NavTabs activeTab={tab} onChangeTab={(tab) => setTab(tab)} />}

      <form onSubmit={formSubmit} className="container">
        {userId === 'new' ? (
          <>
            <TextInputField
              name="email"
              label="Email:"
              value={email || ''}
              placeholder="Email input"
              onChange={handleChange}
            />

            <TextInputField
              name="password"
              label="Password:"
              value={password || ''}
              placeholder="Password input"
              onChange={handleChange}
            />
          </>
        ) : (
          <>
            {tab === Tabs.UserInfoTab && (
              <UserInfoTab user={currentUser} handleChange={handleChange} setDomains={setUserDomains} teams={teams} />
            )}

            {tab === Tabs.TeamTab && (
              <TeamTab
                teams={teams}
                currentTeamId={currentUser.teamId}
                setTeam={(newTeamId) => setCurrentUser((prevState) => ({ ...prevState, teamId: newTeamId }))}
              />
            )}

            {tab === Tabs.CompanyInfoTab && <CompanyInfoTab user={currentUser} handleChange={handleChange} />}

            {tab === Tabs.PaymentsTab && (
              <PaymentsTab
                user={currentUser}
                handleChange={handleChange}
                balances={balances}
                onEditBalnce={() => setIsBalanceModal(true)}
                onChange={() => setIsPaymentModal(true)}
              />
            )}

            {tab === Tabs.TransactionsTab && <TransactionsTab transactions={currentUser.transactions} />}

            {tab === Tabs.CardsTab && (
              <CardsTab cards={cards} activeCard={activeCard} onChangeActiveCard={setUserActiveCard} />
            )}
          </>
        )}

        <div className="field is-grouped">
          <div className="control">
            <button className="button is-link">{isSaving ? <Loader /> : 'Save user'}</button>
          </div>
          {userId !== 'new' && (
            <div className="control">
              <button type="button" className="button is-danger" onClick={() => setIsDeactivate(true)}>
                Deactivate user
              </button>
            </div>
          )}
        </div>
      </form>
      {isDeacivate && (
        <DeletePrompt
          title="Are you sure? Deactivate user?"
          buttons={
            <>
              <button type="button" className="button is-danger" onClick={handleDeactivateUser}>
                Yes
              </button>
              <button type="button" className="button is-link ml-2" onClick={() => setIsDeactivate(false)}>
                Cancel
              </button>
            </>
          }
        />
      )}
    </>
  );
};
