import React, { ChangeEvent } from "react";

type Props = {
  currencyFrom: string,
  currencyTo: string,
  value: number,
  onChangeHandler: (event: ChangeEvent<HTMLInputElement>) => void
}

export const RatesInput: React.FC<Props> = ({
  currencyFrom,
  currencyTo ,
  value,
  onChangeHandler,
}) => {
  return (
    <div className="field is-horizontal">
      <div className="pt-2 pr-2 is-normal">
        <span className="label">1 {currencyFrom} =</span>
      </div>

      <div className="field-body">
        <div className="field has-addons">
          <p className="control">
            <input
              name={`${currencyFrom.toLowerCase()}-${currencyTo.toLowerCase()}`}
              className="input"
              type="number"
              value={value}
              onChange={onChangeHandler}
            />
          </p>

          <p className="control">
            <span className="button is-static">
              {currencyTo}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};
