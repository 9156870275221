import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { PaymentSection, PaymentSectionDataStr } from '../../types/PaymentSection';
import { Link } from 'react-router-dom';
import { Loader } from '../Loader';
import { api } from '../../utils/fetch';
import { Table } from 'antd';
import { enqueueSnackbar } from 'notistack';

export const PaymentSectionsTable: React.FC = () => {
  const columns: ColumnsType<PaymentSectionDataStr> = [
    {
      title: 'Open',
      dataIndex: 'open',
      key: 'open',
      render: (_, item) => (
        <Link className="button is-info" to={`/payment-sections/${item.id}`}>
          Open
        </Link>
      ),
    },
    {
      title: 'Language',
      dataIndex: 'language',
      key: 'language',
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
    },
    {
      title: 'Data',
      dataIndex: 'data',
      key: 'data',
    },
    {
      title: 'Delete',
      dataIndex: 'delete',
      key: 'delete',
      render: (_, item) => (
        <button className="button is-warning" onClick={() => onDeleteHandler(item)}>
          {deletingSection?.id === item.id ? <Loader /> : 'Delete'}
        </button>
      ),
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [deletingSection, setDeletingSection] = useState<PaymentSectionDataStr | null>(null);
  const [paymentSections, setPaymentSections] = useState<PaymentSectionDataStr[] | null>(null);

  async function onDeleteHandler(paymentSectionItem: PaymentSectionDataStr) {
    setDeletingSection(paymentSectionItem);

    try {
      await api.delete('/payment-sections/' + paymentSectionItem.id);
      await getPaymentSectionsFromAPI();
    } catch (e) {
      console.error(e);
      const err = e as Error;
      enqueueSnackbar(`Failed to delete payment: ${err.message}`, { variant: 'error' });
    }

    setDeletingSection(null);
  }

  const getPaymentSectionsFromAPI = async () => {
    setIsLoading(true);

    try {
      const response = await api.get<PaymentSection[]>('/payment-sections');
      const paymentSectionData: PaymentSectionDataStr[] = response.map((section) => ({
        ...section,
        data: section.data.map((dataSection) => `${dataSection.label}: ${dataSection.value}`).join('\n'),
      }));

      setPaymentSections(paymentSectionData);
    } catch (e) {
      const err = e as Error;
      enqueueSnackbar(`Error while loading payment sections: ${err.message}`, { variant: 'error' });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getPaymentSectionsFromAPI();
  }, []);

  return (
    <>
      {isLoading && <Loader />}

      {!isLoading && paymentSections && <Table rowKey="id" dataSource={paymentSections} columns={columns} />}
    </>
  );
};
