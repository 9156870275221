import React, { ChangeEventHandler, FormEvent, useState } from 'react';
import { Client } from '../../types/Client';
import { api } from '../../utils/fetch';
import { Loader } from '../Loader';
import { CurrencySelect } from './CurrencySelect';
import { CreatePaymentLinkModal } from './PaymentLinkModal';
import { Reseller } from '../../types/Reseller';
import { getTotal } from '../../utils/getTotalCost';
import { enqueueSnackbar } from 'notistack';

type Props = {
  client: Client;
  resellers: Reseller[];
  isNewClient: boolean;
};

export const ClientForm: React.FC<Props> = ({ client, isNewClient, resellers }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isPaymentModal, setIsPaymentModal] = useState(false);
  const [isNewClientSaved, setIsNewClientSaved] = useState(false);

  const [newTelegram, setNewTelegram] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [newDomain, setNewDomain] = useState('');

  const [currentClient, setCurrentClient] = useState<Client>(client);
  const saveClientUpdateOnAPI = async () => {
    setIsSaving(true);

    try {
      await api.put('/clients/' + currentClient.id, currentClient);

      enqueueSnackbar('Client updated!', { variant: 'success' });
    } catch (e) {
      const err = e as Error;
      enqueueSnackbar(`Failed to update client: ${err.message}`, { variant: 'error' });
    }
    setIsSaving(false);
  };

  const createClientOnAPI = async () => {
    setIsSaving(true);

    try {
      await api.post<Client>('/clients', currentClient);
      enqueueSnackbar('Client saved!', { variant: 'success' });
    } catch (e) {
      const err = e as Error;
      enqueueSnackbar(`Failed to create client: ${err.message}`, { variant: 'error' });
    }

    setIsNewClientSaved(true);
    setIsSaving(false);
  };

  const formSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isRequiredFieldsFilled()) {
      enqueueSnackbar('All fields are mandatory before the form can be saved', { variant: 'error' });
      return;
    }

    if (isNewClient) {
      createClientOnAPI();

      return;
    }

    saveClientUpdateOnAPI();
  };

  const handleChange: ChangeEventHandler<HTMLInputElement | HTMLSelectElement> = (e) => {
    const { name, value } = e.target;
    if ('costCurrency' === name) {
      setCurrentClient((prevState) => ({
        ...prevState,
        costCurrency: value ? value : prevState.costCurrency,
        billCurrency: value ? value : prevState.billCurrency,
      }));
    } else {
      setCurrentClient((prevState) => ({ ...prevState, [name]: value ? value : undefined }));
    }
  };
  const closePaymentModal = () => {
    setIsPaymentModal(false);
  };

  const openPaymentModal = () => {
    if (!isNewClient) {
      setIsPaymentModal(true);
    } else {
      if (isNewClientSaved) {
        setIsPaymentModal(true);
      } else {
        enqueueSnackbar('To create payment link save a new client first', { variant: 'error' });
      }
    }
  };

  const {
    reseller,
    domains,
    billCurrency,
    cost,
    discountPercent,
    costCurrency,
    billFrom,
    address,
    email,
    telegram,
    referralFee,
    sbFeePercent,
  } = currentClient;

  const isRequiredFieldsFilled = (): boolean => {
    return (
      Array.isArray(domains) &&
      domains.length > 0 &&
      Array.isArray(email) &&
      email.length > 0 &&
      billFrom !== '' &&
      address !== ''
    );
  };

  return (
    <>
      {isPaymentModal && (
        <CreatePaymentLinkModal
          closeModal={closePaymentModal}
          emails={email || []}
          amount={+getTotal({ cost, discountPercent, referralFee, sbFeePercent })}
          currency={billCurrency}
        />
      )}

      <div className="field">
        <button onClick={openPaymentModal} className="button is-success">
          Create payment link
        </button>
      </div>
      <form onSubmit={formSubmitHandler} className="container">
        <div className="field">
          <label className="label">Reseller</label>
          <div className="control">
            <div className="select">
              <select name="reseller" value={reseller} onChange={handleChange}>
                <option value={undefined}>Not Selected</option>
                {resellers.map((r) => (
                  <option key={r.id} value={r.reseller}>
                    {r.reseller}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="field">
          <label className="label">Client’s domain</label>

          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="Type domain and press Enter"
              value={newDomain}
              onChange={(e) => setNewDomain(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  setCurrentClient((prevState) => ({ ...prevState, domains: [...(domains || []), newDomain] }));
                  setNewDomain('');
                }
              }}
            />
          </div>

          <div className="tags">
            {domains &&
              domains.map((domain) => (
                <span key={domain} className="tag is-medium">
                  {domain}
                  <button
                    className="delete is-small"
                    onClick={() =>
                      setCurrentClient((prevState) => ({
                        ...prevState,
                        domains: domains.filter((item) => item !== domain),
                      }))
                    }
                  />
                </span>
              ))}
          </div>
        </div>

        <div className="field">
          <label className="label">Client’s company name</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="Legal name of the company"
              value={billFrom}
              onChange={(e) => setCurrentClient((prevState) => ({ ...prevState, billFrom: e.target.value }))}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">Client’s billing address</label>
          <div className="control">
            <textarea
              className="textarea"
              placeholder="Billing address"
              value={address}
              onChange={(e) => setCurrentClient((prevState) => ({ ...prevState, address: e.target.value }))}
            ></textarea>
          </div>
        </div>

        <label className="label">Saved costs</label>

        <div className="columns">
          <label className="column is-one-quarter">Total amount: </label>

          <div className="column is-three-quarters">
            <div className="field has-addons">
              <input
                className="input"
                type="number"
                name="cost"
                placeholder="Cost"
                title="Cost"
                value={cost}
                onChange={handleChange}
              />
              <div className="select">
                <CurrencySelect name="costCurrency" value={costCurrency} onChange={handleChange} />
              </div>
            </div>
          </div>
        </div>
        <div className="columns">
          <label className="column is-one-quarter">Total discount, %: </label>
          <div className="column is-three-quarters">
            <input
              className="input"
              type="number"
              name="discountPercent"
              placeholder="Discount"
              title="Discount, %"
              min={0}
              max={100}
              value={discountPercent}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="columns">
          <label className="column is-one-quarter">Spendbase Fee, %: </label>
          <div className="column is-three-quarters">
            <input
              className="input"
              type="number"
              placeholder="SB Fee"
              title="SB Fee, %"
              name="sbFeePercent"
              min={0}
              step={0.001}
              value={sbFeePercent}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="columns">
          <label className="column is-one-quarter">Referral Fee: </label>
          <div className="column is-three-quarters">
            <input
              className="input"
              type="number"
              placeholder="Referral fee"
              title="Referral Fee"
              name="referralFee"
              value={referralFee}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="columns">
          <label className="column is-one-quarter bold label">Total price: </label>
          <div className="column is-three-quarters">
            <span className="button is-static">
              {getTotal({ cost, discountPercent, referralFee, sbFeePercent })} {costCurrency}
            </span>
          </div>
        </div>

        <div className="field">
          <label className="label">Invoice currency</label>
          <div className="control">
            <div className="select">
              <CurrencySelect name="billCurrency" value={billCurrency} onChange={handleChange} />
            </div>
          </div>
        </div>

        <div className="field">
          <label className="label">Email</label>

          <div className="control">
            <input
              className="input"
              type="email"
              placeholder="Type email and press Enter"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
              onKeyUp={(e) => {
                e.preventDefault();

                if (e.key === 'Enter') {
                  setCurrentClient((prevState) => ({ ...prevState, email: [...(email || []), newEmail] }));
                  setNewEmail('');
                }
              }}
            />
          </div>

          <div className="tags">
            {email &&
              email.map((emailItem) => (
                <span key={emailItem} className="tag is-medium">
                  {emailItem}
                  <button
                    className="delete is-small"
                    onClick={() =>
                      setCurrentClient((prevState) => ({
                        ...prevState,
                        email: email.filter((item) => item !== emailItem),
                      }))
                    }
                  />
                </span>
              ))}
          </div>
        </div>

        <div className="field">
          <label className="label">Other ways to contact the clients (phone number/ TG acc)</label>

          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="Type and press Enter"
              value={newTelegram}
              onChange={(e) => setNewTelegram(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
              onKeyUp={(e) => {
                e.preventDefault();
                if (e.key === 'Enter') {
                  setCurrentClient((prevState) => ({ ...prevState, telegram: [...(telegram || []), newTelegram] }));
                  setNewTelegram('');
                }
              }}
            />
          </div>

          <div className="tags">
            {telegram &&
              telegram.map((telegramItem) => (
                <span key={telegramItem} className="tag is-medium">
                  {telegramItem}
                  <button
                    className="delete is-small"
                    onClick={() =>
                      setCurrentClient((prevState) => ({
                        ...prevState,
                        telegram: telegram.filter((item) => item !== telegramItem),
                      }))
                    }
                  />
                </span>
              ))}
          </div>
        </div>

        <div className="field is-grouped">
          <div className="control">
            <button className="button is-link">{isSaving ? <Loader /> : 'Save client'}</button>
          </div>
        </div>
      </form>
    </>
  );
};
