import React, { FormEvent, useState } from 'react';
import { PaymentSection } from '../../types/PaymentSection';
import { Loader } from '../Loader';
import { api } from '../../utils/fetch';
import { enqueueSnackbar } from 'notistack';

type Props = {
  paymentSectionItem: PaymentSection;
  isNewPaymentSection: boolean;
};

export const PaymentSectionForm: React.FC<Props> = ({ paymentSectionItem, isNewPaymentSection }) => {
  const [isSaving, setIsSaving] = useState(false);

  const [currentPaymentSection, setCurrentPaymentSection] = useState<PaymentSection>(paymentSectionItem);

  const createPaymentSectionOnAPI = async () => {
    setIsSaving(true);

    try {
      await api.post<PaymentSection>('/payment-sections', currentPaymentSection);

      enqueueSnackbar('Created!', { variant: 'success' });
    } catch (e) {
      const err = e as Error;
      enqueueSnackbar(`Failed to create payment section: ${err.message}`, { variant: 'error' });
    }

    setIsSaving(false);
  };

  const savePaymentSectionUpdateOnAPI = async () => {
    setIsSaving(true);

    try {
      await api.put<PaymentSection>('/payment-sections/' + currentPaymentSection.id, currentPaymentSection);

      enqueueSnackbar('Saved!', { variant: 'success' });
    } catch (e) {
      const err = e as Error;
      enqueueSnackbar(`Failed to save payment section: ${err.message}`, { variant: 'error' });
    }
    setIsSaving(false);
  };

  const { language, currency, data } = currentPaymentSection;

  const formSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!data.length || !currency || !language) {
      enqueueSnackbar('Create all fields before save!', { variant: 'warning' });
      return;
    }

    if (isNewPaymentSection) {
      createPaymentSectionOnAPI();

      return;
    }

    savePaymentSectionUpdateOnAPI();
  };

  return (
    <form onSubmit={formSubmitHandler} className="container">
      <div className="field">
        <label className="label">Language:</label>
        <div className="control">
          <input
            className="input"
            type="text"
            placeholder="Language input"
            value={language}
            onChange={(e) => setCurrentPaymentSection((prevState) => ({ ...prevState, language: e.target.value }))}
          />
        </div>
      </div>

      <div className="field">
        <label className="label">Currency:</label>
        <div className="control">
          <input
            className="input"
            type="text"
            placeholder="Currency input"
            value={currency}
            onChange={(e) => setCurrentPaymentSection((prevState) => ({ ...prevState, currency: e.target.value }))}
          />
        </div>
      </div>

      <label className="label">Data:</label>

      {data.map((dataItem, index) => (
        <div key={index} className="field is-horizontal">
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input
                  className="input"
                  type="text"
                  placeholder="Label"
                  value={dataItem.label}
                  onChange={(e) => {
                    data[index].label = e.target.value;

                    setCurrentPaymentSection((prevState) => ({ ...prevState, data: [...(prevState.data || [])] }));
                  }}
                />
              </p>
            </div>

            <div className="field">
              <p className="control is-expanded">
                <input
                  className="input"
                  type="text"
                  placeholder="Value"
                  value={dataItem.value}
                  onChange={(e) => {
                    data[index].value = e.target.value;

                    setCurrentPaymentSection((prevState) => ({ ...prevState, data: [...(prevState.data || [])] }));
                  }}
                />
              </p>
            </div>

            <button
              type="button"
              className="button is-warning"
              onClick={() => {
                setCurrentPaymentSection((prevState) => ({
                  ...prevState,
                  data: data.filter((_, i) => i !== index),
                }));
              }}
            >
              Delete row
            </button>
          </div>
        </div>
      ))}

      <div className="field">
        <button
          type="button"
          className="button is-success"
          onClick={() => {
            setCurrentPaymentSection((prevState) => ({
              ...prevState,
              data: [...(prevState.data || []), { label: '', value: '' }],
            }));
          }}
        >
          <i className="fas fa-plus"></i>
          Add row
        </button>
      </div>

      <div className="field is-grouped">
        <div className="control">
          <button className="button is-link">{isSaving ? <Loader /> : 'Save payment section'}</button>
        </div>
      </div>
    </form>
  );
};
