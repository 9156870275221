import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { api } from '../../utils/fetch';
import { Link } from 'react-router-dom';
import { Table } from 'antd';
import { Loader } from '../Loader';
import { ProductsPrice } from '../../types/ProductPrice';
import { enqueueSnackbar } from 'notistack';

export const ProductsPricesTable: React.FC = () => {
  const columns: ColumnsType<ProductsPrice> = [
    {
      title: 'Open',
      dataIndex: 'open',
      key: 'open',
      render: (_, item) => (
        <Link className="button is-info" to={`/products-prices/${item.id}`}>
          Open
        </Link>
      ),
    },
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
      render: (_, item) => <span className={item.clientPrice ? '' : 'text-red'}>{item.product}</span>,
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
    },
    {
      title: 'Client cost',
      dataIndex: 'clientPrice',
      key: 'clientPrice',
    },
    {
      title: 'Discount percent',
      dataIndex: 'discountPercent',
      key: 'discountPercent',
    },
    {
      title: 'Delete',
      dataIndex: 'delete',
      key: 'delete',
      render: (_, item) => (
        <button className="button is-warning" onClick={() => onDeleteHandler(item)}>
          {deletingProductsPrice?.id === item.id ? <Loader /> : 'Delete'}
        </button>
      ),
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [deletingProductsPrice, setDeletingProductsPrice] = useState<ProductsPrice | null>(null);
  const [productsPrices, setProductsPrices] = useState<ProductsPrice[] | null>(null);

  async function onDeleteHandler(productsPriceItem: ProductsPrice) {
    setDeletingProductsPrice(productsPriceItem);

    try {
      await api.delete('/products-prices/' + productsPriceItem.id);
      await getProductsPricesFromAPI();
    } catch (e) {
      console.error(e);
      const err = e as Error;
      enqueueSnackbar(`Failed to delete ${productsPriceItem.product}: ${err.message}`, { variant: 'error' });
    }

    setDeletingProductsPrice(null);
  }

  const getProductsPricesFromAPI = async () => {
    setIsLoading(true);

    try {
      const response = await api.get<ProductsPrice[]>('/products-prices');

      setProductsPrices(response);
    } catch (e) {
      const err = e as Error;
      enqueueSnackbar(`Error while loading products: ${err.message}`, { variant: 'error' });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getProductsPricesFromAPI();
  }, []);

  return (
    <>
      {isLoading && <Loader />}

      {!isLoading && productsPrices && <Table rowKey="id" dataSource={productsPrices} columns={columns} />}
    </>
  );
};
