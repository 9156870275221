import React, { useEffect, useReducer } from 'react';
import { ClientForm } from '../../components/ClientForm';
import { Client } from '../../types/Client';
import { getInitialState, loadResellers, reducer } from '../ResellersPage/reducer';

export const CreateClient: React.FC = () => {
  const newClient: Client = {
    // initial state:
    id: 0,
    reseller: 'reseller-ukraine',
    domains: [],
    billCurrency: 'UAH',
    cost: 0,
    discountPercent: 0,
    costCurrency: 'UAH',
    vatPercent: 0,
    billFrom: '',
    address: '',
    email: [],
    telegram: [],
    referralFee: 0,
    sbFeePercent: 0,
  };
  const [state, dispatch] = useReducer(reducer, undefined, getInitialState);
  useEffect(() => {
    void loadResellers({ dispatch });
  }, []);

  if (state.mode === 'initial' || state.mode === 'loading') {
    return <h1 className="title">Loading resellers...</h1>;
  }

  if (state.mode === 'error') {
    return (
      <>
        <h1 className="title">Create a new client</h1>
        <p>There was an error with loading resellers, please refresh the page.</p>
        <p>{state.error}</p>
      </>
    );
  }

  return (
    <>
      <h1 className="title">Create a new client</h1>

      <ClientForm client={newClient} isNewClient={true} resellers={state.resellers} />
    </>
  );
};
