import React from "react";
import { PaymentSection } from "../../types/PaymentSection";
import { PaymentSectionForm } from "../../components/PaymentSectionForm";

export const CreatePaymentSection: React.FC = () => {
  const newReseller: PaymentSection = {
    id: 0,
    language: '',
    currency: '',
    data: [],
  }

  return (
    <>
      <h1 className="title">Create payment section</h1>

      <PaymentSectionForm paymentSectionItem={newReseller} isNewPaymentSection={true} />
    </>
  );
};
