import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Loader } from '../Loader';
import { InvoiceStatuses } from './InvoiceStatuses';
import { RatesInput } from './RatesInput';
import { api } from '../../utils/fetch';
import { LogEntry } from '../../types/LogEntry';
import FileSaver from 'file-saver';
import { enqueueSnackbar } from 'notistack';

type InvoiceStatus = {
  success: string[];
  error: { [key: string]: string[] };
};

export const InvoiceForm: React.FC = () => {
  const [usagesFile, setUsagesFile] = useState<File | null>(null);
  const [isErrorUsagesFile, setIsErrorUsagesFile] = useState(false);
  const [rates, setRates] = useState({
    'usd-uah': 36.9343,
    'eur-uah': 38.9065,
    'usd-eur': 0.93,
    'eur-usd': 1.07,
  });

  const [statuses, setStatuses] = useState<LogEntry[] | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isValidationError, setIsValidationError] = useState(false);
  const [invoicesDate, setInvoicesDate] = useState('');

  const fileUploadHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    if (e.target.files[0].type !== 'text/csv') {
      setIsErrorUsagesFile(true);
      return;
    }

    setIsErrorUsagesFile(false);
    setIsValidationError(false);
    setUsagesFile(e.target.files[0]);
  };

  const formSubmitHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!usagesFile) {
      setIsErrorUsagesFile(true);
      setIsValidationError(true);
      return;
    }

    setIsLoading(true);

    const formData = new FormData();

    formData.append('usages', usagesFile);
    formData.append('rates', JSON.stringify(rates));
    formData.append('date', invoicesDate);

    try {
      const data = await api.sendFiles<LogEntry[]>('/invoices/from-csv', formData);

      await fetch('/api/invoices/download', {
        method: 'GET',
      })
        .then((response) => response.blob())
        .then((blob) => {
          const fileBlop = new Blob([blob]);

          FileSaver.saveAs(fileBlop, 'invoices.zip');
        });

      setStatuses(data);
      setUsagesFile(null);
      enqueueSnackbar('Success', { variant: 'success' });
    } catch (e) {
      console.error(e);
      const err = e as Error;
      enqueueSnackbar(`Download failed: ${err.message}`, { variant: 'error' });
    }

    setIsLoading(false);
  };

  const fetchNewInterbankRates = async () => {
    try {
      const ratesFromApi: any = await api.get('/payments/minfin-rates');
      setRates(ratesFromApi);
    } catch (e) {
      console.error(e);
      const err = e as Error;
      enqueueSnackbar(`Error get minfin rates: ${err.message}`, { variant: 'error' });
    }
  };

  const getInterbankRates = async () => {
    try {
      const ratesFromApi: any = await api.get('/payments/interbank-rates');
      setRates(ratesFromApi);
    } catch (e) {
      console.error(e);
      const err = e as Error;
      enqueueSnackbar(`Error get interbank rates: ${err.message}`, { variant: 'error' });
    }
  };

  const ratesInputHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setRates((prevState) => ({ ...prevState, [event.target.name]: +event.target.value }));
  };

  useEffect(() => {
    getInterbankRates();
  }, []);

  return (
    <form className="form" onSubmit={formSubmitHandler}>
      <div className="field">
        <span className="label">'usages.csv' file</span>
        <div
          className={classNames('file has-name', {
            'is-danger': isErrorUsagesFile,
            'is-success': usagesFile,
          })}
        >
          <label className="file-label">
            <input onChange={fileUploadHandler} className="file-input" type="file" accept=".csv" name="usages" />

            <span className="file-cta">
              <span className="file-icon">
                <i className="fas fa-upload"></i>
              </span>

              <span className="file-label">Choose a 'usages.csv' file</span>
            </span>

            <span className="file-name">{usagesFile ? usagesFile.name : 'No file yet'}</span>
          </label>
        </div>
        {isErrorUsagesFile && <p className="help is-danger">This file type is invalid</p>}
      </div>

      <div className="label">Date of invoices</div>
      <div className="field  has-addons">
        <p className="control">
          <input value={invoicesDate} onChange={(e) => setInvoicesDate(e.target.value)} className="input" type="date" />
        </p>
        <p className="control">
          <a className="button is-static">{'<-- '}Pick a date</a>
        </p>
      </div>

      <label className="label">Rates:</label>

      <RatesInput
        currencyFrom={'USD'}
        currencyTo={'UAH'}
        value={rates['usd-uah']}
        onChangeHandler={ratesInputHandler}
      />

      <RatesInput
        currencyFrom={'EUR'}
        currencyTo={'UAH'}
        value={rates['eur-uah']}
        onChangeHandler={ratesInputHandler}
      />

      <RatesInput
        currencyFrom={'USD'}
        currencyTo={'EUR'}
        value={rates['usd-eur']}
        onChangeHandler={ratesInputHandler}
      />

      <RatesInput
        currencyFrom={'EUR'}
        currencyTo={'USD'}
        value={rates['eur-usd']}
        onChangeHandler={ratesInputHandler}
      />

      {isValidationError && <p className="help is-danger">PLease select usages.csv file to generate invoices!</p>}

      <div className="buttons">
        <button className="button is-info" type="submit">
          {isLoading ? <Loader /> : 'Generate invoices'}
        </button>

        <button
          title="NOTE: Only 10 times per month!"
          onClick={() => fetchNewInterbankRates()}
          className="button is-danger"
          type="button"
        >
          Get Minfin Interbank Rates
        </button>
      </div>

      {statuses && <InvoiceStatuses statuses={statuses} />}
    </form>
  );
};
