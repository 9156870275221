import React from 'react';
import crossIcon from '../../media/xmark-solid.svg';

type Props = {
  searchBy: string;
  onChangeSeachBy: (newSerachBy: string) => void;
  onResetSearchBy: () => void;
};

export const SearchTextField: React.FC<Props> = ({ searchBy, onChangeSeachBy, onResetSearchBy }) => {
  return (
    <div className="field has-addons">
      <p className="control is-expanded has-icons-left">
        <input
          value={searchBy}
          onChange={(e) => onChangeSeachBy(e.target.value)}
          className="input"
          type="text"
          placeholder="Search"
        />
        <span className="icon is-left">
          <i className="fas fa-search" aria-hidden="true"></i>
        </span>
      </p>
      <p className="control">
        <button type="button" onClick={onResetSearchBy} className="button is-link is-outlined">
          <img style={{ height: '120%', color: '#ffe08a' }} src={crossIcon} alt="cross" />
        </button>
      </p>
    </div>
  );
};
