import React, { useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ClientForm } from '../../components/ClientForm';
import { Loader } from '../../components/Loader';
import { Client } from '../../types/Client';
import { api } from '../../utils/fetch';
import { getInitialState, loadResellers, reducer } from '../ResellersPage/reducer';
import { enqueueSnackbar } from 'notistack';

export const ClientPage: React.FC = () => {
  const { clientId } = useParams();

  const [client, setClient] = useState<Client | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [state, dispatch] = useReducer(reducer, undefined, getInitialState);

  useEffect(() => {
    const getClientFromAPI = async () => {
      setIsLoading(true);

      try {
        const response: Client = await api.get<Client>('/clients/' + clientId);

        setClient(response);
      } catch (e) {
        console.error(e);
        const err = e as Error;
        enqueueSnackbar(`Failed to fetch clients: ${err.message}`, { variant: 'error' });
      }

      setIsLoading(false);
    };

    void loadResellers({ dispatch });
    getClientFromAPI();
  }, [clientId]);

  if (state.mode === 'initial' || state.mode === 'loading') {
    return <h1 className="title">Loading resellers...</h1>;
  }

  if (state.mode === 'error') {
    return (
      <>
        <h1 className="title">Create a new client</h1>
        <p>There was an error with loading resellers, please refresh the page.</p>
        <p>{state.error}</p>
      </>
    );
  }

  return (
    <>
      <h1 className="title">Client page</h1>

      {client && <ClientForm client={client} isNewClient={false} resellers={state.resellers} />}

      {isLoading && <Loader />}
    </>
  );
};
