import { FC } from 'react';
import { Link } from 'react-router-dom';
import type { Client } from '../../types/Client';

type ClientsTableProps = {
  clients: Client[];
  onDeleteClick: (id: number) => void;
};

export const ClientsTable: FC<ClientsTableProps> = ({ clients, onDeleteClick }) => (
  <div className="table-container">
    <table className="table is-hoverable is-fullwidth">
      <thead>
        <tr>
          <th>Client</th>
          <th>Reseller</th>
          <th className="has-text-right">Cost</th>
          <th className="has-text-right">Discount</th>
          <th className="has-text-right">Cost With Discount</th>
          <th className="has-text-right">Bill Currency</th>
          <th className="has-text-centered">Actions</th>
        </tr>
      </thead>
      <tbody>
        {clients.map((client) => (
          <tr key={client.id}>
            <td>
              <strong>{client.billFrom || '<no name>'}</strong>
              <br />
              {(client.domains || []).join(', ')}
            </td>
            <td>{client.reseller}</td>
            <td className="has-text-right">
              {client.cost} {client.costCurrency}
            </td>
            <td className="has-text-right">{client.discountPercent}%</td>
            <td className="has-text-right">
              {((client.cost * (100 - client.discountPercent)) / 100.0).toFixed(2)} {client.costCurrency}
            </td>
            <td className="has-text-right">{client.billCurrency}</td>
            <td className="has-text-centered">
              <Link to={`/clients/${client.id}`}>Edit</Link>
              <br />
              <button className="button is-ghost has-text-danger" onClick={() => onDeleteClick(client.id)}>
                Delete
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);
